import {
    AutoComplete,
    Button,
    Carousel,
    Cascader,
    Form,
    Input,
    Modal,
    Popconfirm,
    Select,
    Table,
    Tag,
    Tooltip,
    Image
} from 'antd';
import React, {useEffect, useRef, useState} from 'react';
import styles from "./index.module.css"
import {iHydrant, iPicture} from "../../../interface";
import {
    deleteGarbageBox,
    deleteHydrant, getAllCompanyNotPage,
    getGarbageBoxPage,
} from "../../../api";
import {showFail, showSuccess} from "../../../components/functions";
import LocationModal from "../../../components/locationModal";
import locationIcon from "../../../assets/icons/location.svg"
import noneIcon from "../../../assets/icons/none.svg"
// @ts-ignore
import {debounce} from "lodash";
import AddGarbageBoxModal from "../../../components/addGarbageBoxModal";
import EditGarbageBoxModal from "../../../components/editGarbageBoxModal";

const GarbageBox = () => {
        const streetOptions = [
            {
                "value": "石淙镇",
                "label": "石淙镇"
            },
            {
                "value": "和孚镇",
                "label": "和孚镇"
            },
            {
                "value": "旧馆街道",
                "label": "旧馆街道"
            },
            {
                "value": "练市镇",
                "label": "练市镇"
            },
            {
                "value": "菱湖镇",
                "label": "菱湖镇"
            },
            {
                "value": "南浔镇",
                "label": "南浔镇"
            },
            {
                "value": "千金镇",
                "label": "千金镇"
            },
            {
                "value": "双林镇",
                "label": "双林镇"
            },
            {
                "value": "善琏镇",
                "label": "善琏镇"
            }
        ];
        const isSuperManager = JSON.parse(window.localStorage?.getItem("isSuperManager") as string);
        const isDispatcher = JSON.parse(window.localStorage?.getItem("isDispatcher") as string);
        const companyId = window.localStorage?.getItem("companyId");

        const [pageNum, setPageNum] = useState<number>(1);
        const [pageSize, setPageSize] = useState<number>(10);
        const [total, setTotal] = useState<number>(0);
        const [loading, setLoading] = useState<boolean>(false)

        const [boxList, setBoxList] = useState<any[]>([])
        const onPageChange = (_page: number) => {
            setPageNum(_page);
        };

        const onPageSizeChange = (_page: number, _pageSize: number) => {
            setPageSize(_pageSize);
        };

        const [cleanCompanyList, setCleanCompanyList] = useState<any[]>([])
        useEffect(() => {
            if (isSuperManager) {
                getAllCompanyNotPage().then((res: any) => {
                    if (res?.code === 200) {
                        setCleanCompanyList(res?.data?.map((d: any) => {
                            return {
                                value: d?.companyId,
                                label: d?.companyName
                            }
                        }))
                    }
                })
            }
        }, [])

        let params: any = {}
        const handleGetBoxList = async (pageNum: number, pageSize: number, searchCondition?: any) => {
            setLoading(true);
            params.pageSize = pageSize;
            params.pageNum = pageNum;
            params = {...params, ...searchCondition}
            getGarbageBoxPage(params).then((res: any) => {
                setLoading(false);
                if (res?.code === 200) {
                    setTotal(Number(res?.data?.total));
                    setBoxList(res?.data?.records);
                } else {
                    showFail(res?.msg)
                }
            }).catch((err) => {
                console.log(err)
            })
        }

        useEffect(() => {
            handleGetBoxList(pageNum, pageSize, newSearchCondition);
        }, [pageSize, pageNum])

        const [searchCondition, setSearchCondition] = useState({
            companyId: isDispatcher ? companyId : "",
            streetName: "",
            commName: "",
            boxName: "",
        });
        const [newSearchCondition, setNewSearchCondition] = useState(searchCondition);

        const handleStreetNameChange = (e: any) => {
            setSearchCondition({...searchCondition, streetName: e})
        }

        const handleCommNameChange = (e: any) => {
            setSearchCondition({...searchCondition, commName: e?.target?.value})
        }

        const handleBoxNameChange = (e: any) => {
            setSearchCondition({...searchCondition, boxName: e?.target?.value})
        }

        //位置对话框
        const [hydrantLng, setHydrantLng] = useState<string | number>();
        const [hydrantLat, setHydrantLat] = useState();
        const [locationModalOpen, setLocationModalOpen] = useState(false);
        const handleCloseLocationModal = () => {
            setLocationModalOpen(false);
        }


        const columns: any = [
            {
                title: '编码',
                dataIndex: 'boxCode',
                key: 'boxCode',
                fixed: 'left',
                width: 180,
            },
            {
                title: '公司名称',
                dataIndex: ['cleanCompany','companyName'],
            },
            {
                title: '所在街道',
                dataIndex: 'streetName',
                key: 'streetName'
            },
            {
                title: '所处社区',
                dataIndex: 'commName',
                key: 'commName',
            },
            {
                title: '最大装载量(kg)',
                dataIndex: 'maxLoadWeight',
                key: 'maxLoadWeight',
            },
            {
                title: '负责人名称',
                dataIndex: 'leader',
                key: 'leader',
            },
            {
                title: '联系电话',
                dataIndex: 'telphone',
                key: 'telphone',
            },
            {
                title: '定位',
                dataIndex: 'location',
                key: 'location',
                render: (_: any, record: any) => (
                    <>
                        {(record?.longitude && record?.latitude) ? (
                            <img style={{cursor: 'pointer'}} src={locationIcon} width={20} height={20} alt=""
                                 onClick={() => {
                                     setLocationModalOpen(true);
                                     setHydrantLat(record?.latitude);
                                     setHydrantLng(record?.longitude);
                                 }}/>
                        ) : (<div>
                            <Tooltip title={'该箱体暂无定位'}>
                                <img src={noneIcon} alt="" width={20} height={20}/>
                            </Tooltip>
                        </div>)}
                    </>
                )
            },
            {
                title: '操作',
                dataIndex: 'operation',
                key: 'operation',
                fixed: 'right',
                render: (_: any, record: any) => (
                    <div>

                        <Button type={'link'} onClick={() => {
                            setHydrantRecord(record);
                            setEditModalVisible(true);
                        }}>修改</Button>

                        <Popconfirm title={"是否确认删除该箱体"} onConfirm={() => {
                            deleteGarbageBox({boxCode: record?.boxCode}).then((res: any) => {
                                if (res?.code === 200) {
                                    handleGetBoxList(pageNum, pageSize, newSearchCondition).then(() => {
                                        showSuccess(res?.msg);
                                    })
                                } else {
                                    showFail(res?.msg)
                                }
                            }).catch((err) => {
                                console.log(err)
                            })
                        }}>
                            <Button danger type={'link'} color={'danger'}>删除</Button>
                        </Popconfirm>

                    </div>
                )
            },
        ];

        //新增
        const [addModalVisible, setAddModalVisible] = useState<boolean>(false);
        const handleCloseAddModal = () => {
            setAddModalVisible(false);
        }

        //修改
        const [hydrantRecord, setHydrantRecord] = useState<iHydrant>();
        const [editModalVisible, setEditModalVisible] = useState<boolean>(false);
        const handleCloseEditModal = () => {
            setEditModalVisible(false);
        }

        return (
            <div className={styles.main}>
                <div className={styles.mainHead}>
                    <div className={styles.searchContent}>

                        {isSuperManager && (
                            <Select style={{width: '180px', marginRight: '30px', marginTop: '10px'}}
                                    options={cleanCompanyList} placeholder="清运公司" allowClear/>
                        )}

                        <Select style={{width: '180px', marginRight: '30px', marginTop: '10px'}} allowClear
                                options={streetOptions} placeholder="街道/乡镇" onChange={handleStreetNameChange}/>

                        <Input style={{width: '180px', marginRight: '30px', marginTop: '10px'}}
                               onChange={handleCommNameChange} placeholder={'所处社区名称'} allowClear/>

                        <Input style={{width: '180px', marginRight: '30px', marginTop: '10px'}}
                               onChange={handleBoxNameChange} placeholder={'箱体名称'} allowClear
                        />


                    </div>
                    <div className={styles.searchButton}>

                        <Button style={{marginRight: '20px', marginTop: '10px'}} type='primary' onClick={() => {
                            handleGetBoxList(1, 10, searchCondition);
                            setPageNum(1);
                            setPageSize(10);
                            setNewSearchCondition(searchCondition)
                        }}>查询
                        </Button>

                        <Button type='primary' style={{marginRight: '20px', marginTop: '10px'}} onClick={() => {
                            setAddModalVisible(true);
                        }}>新增
                        </Button>

                    </div>

                </div>

                <Table rowKey={'boxCode'} columns={columns} dataSource={boxList} loading={loading}
                       pagination={{
                           current: pageNum,
                           onChange: onPageChange,
                           onShowSizeChange: onPageSizeChange,
                           total: total,
                           showSizeChanger: true,
                           defaultPageSize: pageSize,
                           pageSizeOptions: ['10', '20', '50', '100'],
                       }}/>

                <LocationModal open={locationModalOpen} onCancel={handleCloseLocationModal}
                               lat={hydrantLat} lng={hydrantLng}/>

                <AddGarbageBoxModal open={addModalVisible} onClose={handleCloseAddModal} streetOptions={streetOptions}
                                    handleGetBox={async () => {
                                        await handleGetBoxList(pageNum, pageSize, newSearchCondition)
                                    }}/>

                <EditGarbageBoxModal open={editModalVisible} onClose={handleCloseEditModal} streetOptions={streetOptions}
                                     handleGetBox={async () => {
                                         await handleGetBoxList(pageNum, pageSize, newSearchCondition)
                                     }} boxRecord={hydrantRecord}/>
            </div>
        );
    }
;

export default GarbageBox;