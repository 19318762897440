import React, {useEffect, useRef, useState} from 'react';
import {
    Button,
    Checkbox,
    Col,
    Divider,
    Form,
    Input,
    message,
    Modal,
    Popconfirm,
    Row,
    Select,
    Space, Spin,
    Table, Tooltip,
    TreeSelect, Typography
} from "antd";
import './index.css'
import {PlusOutlined, TeamOutlined} from "@ant-design/icons";
import {AxiosError} from "axios";
import PickLocationModal from "../../../components/pickLocationModal";
import PickLocationModalUpdate from "../../../components/pickLocationModal";
// @ts-ignore
import {debounce} from "lodash";
import {
    getUser,
    addUser,
    deleteUser,
    updateUser,
    searchForUser,
    getDeptTree, getDeptById, getAllCompanyNotPage
} from "../../../api";
import {showWarning} from "../../../components/functions";

const {Option} = Select;

const {AMap} = window as any
//用户管理
const UserManagement = () => {
    const isSuperManager = JSON.parse(window.localStorage?.getItem("isSuperManager") as string);
    const isManager = JSON.parse(window.localStorage?.getItem("isManager") as string);
    const isInspector = JSON.parse(window.localStorage?.getItem("isInspector") as string);
    const isAreaManager = JSON.parse(window.localStorage?.getItem("isAreaManager") as string);

    //数据接口
    interface DataType {
        key?: React.Key,
        pageNum?: number,
        pageSize?: number,
        username?: string,
        xqName?: string,
        zjName?: string,
        Authorization?: string,
    }

    let [dataSource, setDataSource] = useState<any>([]);
    const [currentPage, setCurrentPage] = useState(1);
    let [orderTotal, setOrderTotal] = useState(0);
    const [deptTree, setDeptTree] = useState<any>([]);
    const [depts, setDepts] = useState<any>([]);
    const [addSpin, setAddSpin] = useState<boolean>(false);
    const [updateSpin, setUpdateSpin] = useState<boolean>(false);

    const [isCompany, setIsCompany] = useState<boolean>();
    const [isAreaZD, setIsAreaZD] = useState<boolean>();
    interface depttree {
        value?: string,
        title?: string,
        children?: any[],
    }
    const changeName = (e: any) => {
        let v = e?.target?.value;
        if (v) {
            let params: any = {companyName: v};

            getAllCompanyNotPage(params).then((res: any) => {
                console.log(res)
                if (res?.code === 200) {
                    setCompanyList(res?.data?.map((d: any) => {
                        return {
                            value: d?.companyId,
                            label: d.companyName
                        }
                    }))
                }
            })
        } else {
            showWarning("请输入归属人姓名或手机号")
        }
    }
    function tree(list: depttree[], res: any[]) {
        console.log(res)
        for (let i = 0; i < res.length; i++) {
            if (res[i].children) {
                // @ts-ignore
                list.push({value: res[i].deptId, title: res[i].name, children: []})
            } else {
                // @ts-ignore
                list.push({value: res[i].deptId, title: res[i].name})
            }
            // @ts-ignore
            if (res[i].children) {
                // @ts-ignore
                tree(list[i].children, res[i].children)
            }
        }
        return list
    }

    useEffect(() => {
        let paramsw = {roleType: 0}
        searchForUser(paramsw).then((res: any) => {
            setSelectSysRoles(res.data)
        })
        // getAllCompanyNotPage({}).then((res: any) => {
        //     console.log(res)
        //     if (res?.code === 200) {
        //         setCompanyList(res?.data?.map((d: any) => {
        //             return {
        //                 value: d?.companyId,
        //                 label: d.companyName
        //             }
        //         }))
        //     }
        // })
        let paramsx = {}
        getDeptTree(paramsx).then((res: any) => {
            setDeptTree(res.data);
            let a: depttree[] = []
            let b = []
            b.push(res.data)
            let list = tree(a, b)
            setDepts(list)
            console.log(list)
        }, (err: AxiosError) => {
            console.log(err);
        });

        let params: DataType = {
            pageNum: 1,
            pageSize: 10,
        };
        getUser(params).then((res: any) => {
            if (/^4[0-9][0-9]/.test(res.code)) {
                message.error(res.msg);
            } else {
                // for (let i = 0; i < res.data?.records?.length; i++) {
                //     if(res.data.records[i].isMaintenance==0){
                //         res.data.records[i].isMaintenance='否'
                //     }if(res.data.records[i].isMaintenance==1) {
                //         res.data.records[i].isMaintenance='是'
                //     }
                // }
                for (let i = 0; i < res.data.records.length; i++) {
                    if (res.data.records[i].sysRoles.length > 0) {
                        let a = ''
                        for (let j = 0; j < res.data.records[i].sysRoles.length; j++) {
                            if (j < res.data.records[i].sysRoles.length - 1) {
                                a += res.data.records[i].sysRoles[j].name + ','
                            } else {
                                a += res.data.records[i].sysRoles[j].name
                            }
                        }
                        res.data.records[i].roleName = a;
                    }
                }
                setDataSource(res.data.records);
                setOrderTotal(res.data.total);
                setCurrentPage(res.data.current);
            }
        }, (err: AxiosError) => {
            console.log(err);
        })
    }, [])

    let [inputName, setInputName] = useState<any>('');
    let [inputMobile, setInputMobile] = useState<any>('');


    //查询用户
    const onGetUser = (pageNum: number, pageSize: number) => {
        let params = {
            username: inputName,
            mobile: inputMobile,
            pageNum: pageNum,
            pageSize: pageSize,
        };

        getUser(params).then((res: any) => {
            if (/^4[0-9][0-9]/.test(res.code)) {
                message.error(res.msg);
                //setCustOrderLoading(false);
            } else {

                for (let i = 0; i < res.data.records.length; i++) {
                    if (res.data.records[i].sysRoles.length > 0) {
                        let a = ''
                        for (let j = 0; j < res.data.records[i].sysRoles.length; j++) {
                            if (j < res.data.records[i].sysRoles.length - 1) {
                                a += res.data.records[i].sysRoles[j].name + ','
                            } else {
                                a += res.data.records[i].sysRoles[j].name
                            }
                        }
                        res.data.records[i].roleName = a;
                    }
                }
                setDataSource(res.data.records);
                setOrderTotal(res.data.total);
                setCurrentPage(res.data.current);
            }
        }, (err: AxiosError) => {
            console.log(err);
        })
    }

    //新增用户数据
    const [isAddModalOpen, setIsAddModalOpen] = useState(false);
    const [username, setUserName] = useState<any>('');
    const [mobile, setMobile] = useState<any>('');
    const [isMaintenance, setIsMaintenance] = useState<any>('');
    const [sysRoles, setSysRoles] = useState<any[]>([]);
    const [selectSysRoles, setSelectSysRoles] = useState<any[]>([]);
    //新增用户
    const onAddUser = (e: any) => {
        console.log('add')
        setAddSpin(true)
        let _sysRole2 = [];
            for (let i = 0; i < e.roles1.length; i++) {
                _sysRole2.push({'id': e.roles1[i]})
            }
        let params2 = {
            username: e.username,
            dept: e.dept,
            deptId: e.deptId,
            mobile: e.mobile,
            wxMobile: e.wxMobile,
            companyId:e.companyId,
            sysRoles: _sysRole2
        }

        addUser(params2).then((res: any) => {
            if (/^5[0-9][0-9]/.test(res.code)) {
                message.error(res.msg);
                setAddSpin(false)
            } else {
                message.success(res.msg);
                form.resetFields()
                setSysRoles([])
                setAddSpin(false)
                setIsAddModalOpen(false);
                onGetUser(currentPage, 10);
                setCompanyList([])
            }
        }, (err: AxiosError) => {
            setAddSpin(false)
            console.log(err);
        })
    }

    const onupdateUser = (e: any) => {
        setUpdateSpin(true)
        let _sysRole2 = [];
            for (let i = 0; i < e.roles1.length; i++) {
                _sysRole2.push({'id': e.roles1[i]})
            }

        let params2 = {
                userId: updateUserdata.userId,
                username: e.username,
                mobile: e.mobile,
                wxMobile: e.wxMobile,
                companyId:e.companyId,
                sysRoles: _sysRole2,
                deptId: e.deptId,
        }
        updateUser(params2).then((res: any) => {
            if (/^5[0-9][0-9]/.test(res.code)) {
                message.error(res.msg);
                setUpdateSpin(false)
            } else {
                message.success(res.msg);
                form2.resetFields()
                setUpdateSpin(false)
                setUpdateModalOpen(false);
                setIsMaintenance('')
                setMaintenanceStaffid('')
                setIsFactory('')
                setTeams([])
                setSysRoles([])
                setUpdateUserdata('')
                setIsCompany(false)
                setIsAreaZD(false)
                setCompanyList([])
                onGetUser(currentPage, 10);
            }
        }, (err: AxiosError) => {
            setUpdateSpin(false)
            console.log(err);
        })
    }


    //删除用户
    const onDeleteUser = (val: any) => {
        console.log(val)
        let params = {
            userId: val.userId,
        }

        deleteUser(params).then((res: any) => {
            if (/^5[0-9][0-9]/.test(res.code)) {
                message.error(res.msg);
            } else {
                message.success(res.msg);
                onGetUser(currentPage, 10);

            }
        }, (err: AxiosError) => {
            console.log(err);
        })

    };


    //修改对话框
    const [updateModalOpen, setUpdateModalOpen] = useState(false);
    const handleUpdateCancel = () => {
        setUpdateModalOpen(false);
    };
    //保存原修改数据
    const [updateUserdata, setUpdateUserdata] = useState<any>('');

    const DetailModal = ({open, onCancel, record}: { open: any, onCancel: any, record: any }) => {

        return (
            <Modal
                title='维修人员详情'
                open={open}
                onCancel={onCancel}
                footer={[
                    <Button key="back" onClick={() => onCancel()}>
                        取消
                    </Button>]}
            >

                <Form>
                    <Form.Item style={{width: '300px', marginBottom: '10px'}}
                               label={"服务区域"}>{record?.staffArea}</Form.Item>
                    <Form.Item style={{width: '300px', marginBottom: '10px'}}
                               label={"维护单位名"}>{record?.maintenanceOrganization?.maintenanceName}</Form.Item>
                    {record?.maintenanceTeam ? <Form.Item style={{width: '300px', marginBottom: '10px'}}
                                                          label={"维护团队"}>{record?.maintenanceTeam?.teamName}</Form.Item> : ''}
                    <Form.Item style={{width: '300px', marginBottom: '10px'}}
                               label={"在线情况"}>{record?.staffIsonline}</Form.Item>
                    <Form.Item style={{width: '300px', marginBottom: '10px'}}
                               label={"在职情况"}>{record?.staffState}</Form.Item>
                </Form>
            </Modal>
        )
    }

    //保存角色数据
    const [role, setRole] = useState<any>('');


    //修改权限对话框
    const [userRoleModalOpen, setUserRoleModalOpen] = useState(false);
    const handleUserRoleCancel = () => {
        setUserRoleModalOpen(false);
    };


    const [handleDetailOpen, setHandleDetailOpen] = useState(false);
    const handleDetailCancel = () => {
        setHandleDetailOpen(false);
    };

    const [isFactory, setIsFactory] = useState<any>();
    const [companyList, setCompanyList] = useState<any[]>([]);

    //表头数据
    const Columns = [
        {
            title: '用户名',
            dataIndex: 'username',
            width: 200
        },

       {
            title: '联系电话',
            dataIndex: 'mobile',
            width: 200
        },
        {
            title: '清运单位',
            dataIndex: ['cleanCompany','companyName'],
            width: 200
        },

        {
            title: '角色',
            dataIndex: 'roleName',
            width: 200
        },
        {
            title: '部门名称',
            dataIndex: ['sysDept','name'],
            width: 200
        },
        {
            title: '操作',
            dataIndex: 'operate',
            width: 200,
            render: (_: any, record: any) => (
                (dataSource.length >= 1 && (isSuperManager || isManager)) ? (
                    <Space>
                        <Popconfirm title="确认删除吗?" onConfirm={() => onDeleteUser(record)}>
                            <a>删除</a>
                        </Popconfirm>

                        <a onClick={() => {
                            setUpdateUserdata(record);

                            setIsFactory(record.isFactory)
                            let params = {roleType: 0}
                            searchForUser(params).then((res2: any) => {
                                setSelectSysRoles(res2.data)
                                let _sysRole: any = []
                                let a=false
                                let b=false
                                for (let i = 0; i < record.sysRoles.length; i++) {
                                    _sysRole.push(record.sysRoles[i].id)
                                    if(record.sysRoles[i].id==='3'){
                                        a=true
                                    }
                                    if(record.sysRoles[i].id==='1833698519080370177'){
                                        b=true
                                    }
                                }
                                if(record.companyId) {
                                    setCompanyList([{label: record.cleanCompany.companyName, value: record.companyId}])
                                    setIsCompany(true)
                                }
                                setIsAreaZD(b)
                                setUpdateModalOpen(true);
                                setSysRoles(record.sysRoles)
                                form2.setFieldsValue({
                                    username: record.username,
                                    organization: record.username,
                                    dept: record.dept,
                                    deptId: record.deptId,
                                    address: record.address,
                                    mobile: record.mobile,
                                    wxMobile: record.wxMobile,
                                    isMaintenance: record.isMaintenance,
                                    roles1: _sysRole,
                                    isFactory: record.isFactory,
                                    factoryId: record.factoryId,
                                    companyId:record.companyId
                                })
                            })
                        }}>修改</a>

                    </Space>
                ) : null
            )
        },

    ]

    const [form] = Form.useForm()
    const [form2] = Form.useForm()
    const [maintenanceStaffid, setMaintenanceStaffid] = useState<any>('')
    const [factoryId, setFactoryId] = useState<any>([])
    const [teams, setTeams] = useState<any[]>([])
    return (
        <div className='user_management_layout'>


            <div className='user_management_search'>
                <div className='user_management_header'><TeamOutlined/> 工号管理</div>
                <div style={{width: '80%'}}>
                    <Row gutter={24}>
                        <Col span={4}>
                            <Input placeholder="请输入用户名" onChange={(event: any) => {
                                setInputName(event.target.value)
                            }} allowClear={true}/>
                        </Col>
                        <Col span={4}>
                            <Input placeholder={"手机号"} onChange={(event: any) => {
                                setInputMobile(event.target.value)
                            }} allowClear={true}/>
                        </Col>

                        <Col span={4}>
                            <Button style={{float: 'right'}} type="primary"
                                    onClick={debounce(() => onGetUser(1, 10), 300)}>查询</Button>
                        </Col>
                    </Row>
                </div>
            </div>
            <Divider style={{margin: 0}}/>

            {(isSuperManager || isManager) && (
                <div className='user_management_add'>
                    <Button onClick={() => {
                        setIsAddModalOpen(true)
                    }} type="primary"><PlusOutlined/>新增</Button>
                </div>
            )}

            <div>
                <Table
                    rowKey="id"
                    columns={Columns}
                    dataSource={dataSource}
                    pagination={{
                        //current: currentPage,
                        onChange: (page: number, pagesize: number) => onGetUser(page, pagesize),
                        total: orderTotal,
                        pageSize: 10,
                        showSizeChanger: false,
                        onShowSizeChange: (page: number, pagesize: number) => onGetUser(page, pagesize),
                        showTotal: ((total: number) => {
                            return `共${total}条`
                        })
                    }}
                />
            </div>

            <Modal
                title='新增用户'
                open={isAddModalOpen}
                onCancel={() => {setIsAddModalOpen(false)
                    form.resetFields()
                    setIsAreaZD(false)
                    setIsCompany(false)
                }}
                footer={[
                    <Button key="back" onClick={() => {
                        setIsAddModalOpen(false)
                        setCompanyList([])
                        form.resetFields()
                        setIsCompany(false)
                        setIsAreaZD(false)
                    }

                    }>
                        取消
                    </Button>]}
            >
                <Spin tip="Loading..." spinning={addSpin}>
                    <Form name="basic"
                          style={{maxWidth: 600}}
                          onFinish={(e: any) =>{
                              onAddUser(e)

                          }}
                          form={form}>
                        <Form.Item name={"username"} rules={[{required: true, message: '请输入用户名！'}]} label={'用户名'}><Input
                            onChange={(e) => setUserName(e.target.value)}/></Form.Item>
                        <Form.Item name={"mobile"} rules={[{required: true, message: '请输入手机号!'}]} label={'手机号'}><Input
                            style={{width: '300px'}} onChange={(event: any) => {
                            setMobile(event.target.value)
                        }}/></Form.Item>

                        <Form.Item name={"roles1"} style={{width: '100%'}} label={'用户角色'}
                                   rules={[{required: true, message: '请选择用户角色!'}]}>
                            <Select mode={"multiple"} style={{width: '100%'}} onChange={(e: any) => {
                                setSysRoles(e)
                                let a=false
                                let b=false
                                for (let i = 0; i < e.length; i++) {
                                    if(e[i]==='3'){
                                        a=true
                                    }
                                    if(e[i]==='1833698519080370177'){
                                        b=true
                                    }

                                }
                                setIsCompany(a)
                                setIsAreaZD(b)
                            }}>
                                {selectSysRoles.map((value => {
                                    return (<Option key={value.name} value={value.id}>{value.name}</Option>)
                                }))}
                            </Select>
                        </Form.Item>
                        {isCompany ? <Form.Item
                            name="companyId"
                            label="归属清运单位"
                            rules={[
                                {
                                    required: true, message: "归属单位不能为空"
                                },
                            ]}
                        >
                            <Select
                                allowClear={true}
                                placeholder={"请选择归属单位"}
                                dropdownRender={(menu) => (
                                    <>
                                        {menu}
                                        <Divider style={{margin: '8px 0'}}/>
                                        <Space style={{padding: '4px 8px 4px '}}>
                                            <Input
                                                allowClear
                                                placeholder="请输入单位名称"
                                                onChange={debounce(changeName, 600)}
                                            />
                                        </Space>
                                    </>
                                )}
                                options={companyList}
                            />
                        </Form.Item>:''}
                        {isAreaZD?<Form.Item label={'归属部门'} name={"deptId"} rules={[{required: true, message: '请选择部门！'}]}>
                            <TreeSelect
                                showSearch
                                style={{width: '100%'}}
                                // value={deptTree}
                                dropdownStyle={{maxHeight: 400, overflow: 'auto'}}
                                allowClear
                                treeDefaultExpandAll
                                onChange={(e) => {
                                    console.log(e)
                                            if (e === '1' || e==='2') {
                                                form.setFieldValue("deptId", '')
                                                message.error("请选择中队")
                                            }
                                }
                                }
                                treeData={depts}
                            />
                        </Form.Item>:''}
                        <Button type="primary" htmlType="submit">
                            提交
                        </Button>
                    </Form>
                </Spin>
            </Modal>


            <Modal
                title='修改用户'
                open={updateModalOpen}
                onCancel={() => {
                    setUpdateModalOpen(false)
                    form2.resetFields()
                    setIsMaintenance('')
                    setMaintenanceStaffid('')
                    setIsFactory('')
                    setTeams([])
                    setSysRoles([])
                    setUpdateUserdata('')
                    setIsCompany(false)
                    setIsAreaZD(false)
                    setCompanyList([])
                    form.resetFields()
                    setIsCompany(false)
                    setIsAreaZD(false)
                }}
                footer={[
                    <Button key="back" onClick={() => {
                        setUpdateModalOpen(false)
                        form.resetFields()
                        setIsCompany(false)
                        setIsAreaZD(false)
                    }

                    }>
                        取消
                    </Button>]}
            >
                <Spin tip="Loading..." spinning={updateSpin}>
                    <Form name="basic"

                          style={{maxWidth: 600}}
                          onFinish={(e: any) => onupdateUser(e)}
                          form={form2}>
                        <Form.Item name={"username"} rules={[{required: true, message: '请输入用户名！'}]} label={'用户名'}><Input
                            onChange={(e) => setUserName(e.target.value)}/></Form.Item>
                        <Form.Item name={"mobile"} rules={[{required: true, message: '请输入手机号!'}]} label={'手机号'}><Input
                            style={{width: '300px'}} onChange={(event: any) => {
                            setMobile(event.target.value)
                        }}/></Form.Item>
                        <Form.Item name={"roles1"} style={{width: '100%'}} label={'用户角色'}
                                   rules={[{required: true, message: '请选择用户角色!'}]}>
                            <Select mode={"multiple"} style={{width: '100%'}} onChange={(e: any) => {
                                setSysRoles(e)
                                let a=false
                                let b=false
                                for (let i = 0; i < e.length; i++) {
                                    if(e[i]==='3'){
                                        a=true
                                    }
                                    if(e[i]==='1833698519080370177'){
                                        b=true
                                    }

                                }
                                setIsCompany(a)
                                setIsAreaZD(b)
                            }}>
                                {selectSysRoles.map((value => {
                                    return (<Option key={value.name} value={value.id}>{value.name}</Option>)
                                }))}
                            </Select>
                        </Form.Item>
                        {isCompany?
                        <Form.Item
                            name="companyId"
                            label="归属单位"
                            rules={[
                                {
                                    required: true, message: "归属单位不能为空"
                                },
                            ]}
                        >
                            <Select
                                allowClear={true}
                                placeholder={"请选择归属单位"}
                                dropdownRender={(menu) => (
                                    <>
                                        {menu}
                                        <Divider style={{margin: '8px 0'}}/>
                                        <Space style={{padding: '4px 8px 4px '}}>
                                            <Input
                                                allowClear
                                                placeholder="请输入单位名称"
                                                onChange={debounce(changeName, 600)}
                                            />
                                        </Space>
                                    </>
                                )}
                                options={companyList}
                            />
                        </Form.Item>:''}
                        { isAreaZD?<Form.Item label={'归属部门'} name={"deptId"} rules={[{required: true, message: '请选择部门！'}]}>
                            <TreeSelect
                                showSearch
                                style={{width: '100%'}}
                                // value={deptTree}
                                dropdownStyle={{maxHeight: 400, overflow: 'auto'}}
                                allowClear
                                treeDefaultExpandAll
                                onChange={(e) => {
                                    getDeptById({deptId: e}).then(
                                        (res: any) => {
                                            if (res.data?.deptType === 0) {
                                                form2.setFieldValue("deptId", '')
                                                message.error("区域无法选择，请选择具体部门")
                                            }
                                        }
                                    )
                                }
                                }
                                treeData={depts}
                            />
                        </Form.Item>:''
                        }




                        <Button type="primary" htmlType="submit">
                            提交
                        </Button>
                    </Form>
                </Spin>
            </Modal>
            <DetailModal record={updateUserdata} onCancel={handleDetailCancel} open={handleDetailOpen}/>
        </div>
    );
};

export default UserManagement;