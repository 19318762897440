import React, {useEffect, useState} from 'react';
import styles from "./index.module.css"
import {useNavigate} from "react-router-dom";
import {AutoComplete, Button, Cascader, Input, Pagination, Image, Switch, Tooltip, Modal} from 'antd';
import {
    ArrowLeftOutlined
} from '@ant-design/icons';
import {Map, InfoWindow, Marker, Markers} from 'react-amap';
import {ArrowCircleRight} from "@icon-park/react";
import {getAreaTree, getHydrantList, getRoads} from "../../api";
import {getAreaPath, showWarning, transform} from '../../components/functions';


const HydrantMap = () => {
    const navigate = useNavigate();

    const [oriMarkers, setOriMarkers] = useState<any[]>([])
    const [markers, setMarkers] = useState<any[]>([]);
    const [centerLng, setCenterLng] = useState(120.09);
    const [centerLat, setCenterLat] = useState(30.89);
    const [zoom, setZoom] = useState(13);


    //switch按钮
    const [showHydrantList, setShowHydrantList] = useState(true);
    const onSwitchChange = (checked: any) => {
        setShowHydrantList(checked);
    }

    const [total, setTotal] = useState(0);
    const [page, setPage] = useState(1);
    const pageSize = 5;
    const handlePageChange = (v: number) => {
        setPage(v);
    }


    //信息框
    const [checkedId, setCheckedId] = useState()  //选中宿舍的id
    const [info, setInfo] = useState<any>();
    // console.log('in',info)
    const [infoWindowVisible, setInfoWindowVisible] = useState(false);


    let infoWindow = ` 
                    <div>
                        <h3>${info?.name}</h3>
                        <p>道路: ${info?.road || "无"}</p>
                        <p>地址: ${info?.address}</p>
                        <p>二维码: ${info?.qrCode || "未绑定"}</p>
                    </div>`

    const [hydrantDetailVisible, setHydrantDetailVisible] = useState<boolean>(false);
    const handleHydrantDetailModalClose = () => {
        setHydrantDetailVisible(false)
    }


    const [searchCondition, setSearchCondition] = useState({
        hydrantName: "",
        hydrantAddress: "",
        areaId: "",
        qrCode: "",
        road: ""
    })

    const handleNameChange = (e: any) => {
        setSearchCondition({
            ...searchCondition,
            hydrantName: e?.target?.value
        })
    }

    const handleAddressChange = (e: any) => {
        setSearchCondition({
            ...searchCondition,
            hydrantAddress: e?.target?.value
        })
    }

    const handleDistrictChange = (v: any) => {
        if (v) {
            setSearchCondition({...searchCondition, areaId: v[v?.length - 1]})
        } else {
            setSearchCondition({...searchCondition, areaId: ""})
        }
    }

    const handleQrcodeChange = (e: any) => {
        setSearchCondition({
            ...searchCondition,
            qrCode: e?.target?.value
        })
    }

    const handleRoadChange = (v: any) => {
        setSearchCondition({
            ...searchCondition,
            road: v
        })
    }

    const [districtOptions, setDistrictOptions] = useState<any>()

    let params: any = {};
    const handleGetHydrantList = () => {
        if (searchCondition?.hydrantAddress) {
            params.hydrantAddress = searchCondition?.hydrantAddress
        }
        if (searchCondition?.hydrantName) {
            params.hydrantName = searchCondition?.hydrantName
        }
        if (searchCondition?.areaId) {
            params.areaId = searchCondition?.areaId
        }
        if (searchCondition?.qrCode) {
            params.qrCode = searchCondition?.qrCode
        }
        if (searchCondition?.road) {
            params.road = searchCondition?.road
        }
        getHydrantList(params).then((res: any) => {
            if (res?.code === 200) {
                getAreaTree().then((res2) => {
                    setDistrictOptions(transform(res2?.data?.children?.find((d: any) => (d?.areaId === '4'))));
                    setTotal(res?.data?.length);
                    let oriMarkers = res?.data?.map((m: any) => {
                        return {
                            "position": {
                                longitude: m?.lng,
                                latitude: m?.lat
                            },
                            "name": m?.hydrantName,
                            "address": m?.hydrantAddress,
                            "id": m?.hydrantId,
                            "qrCode": m?.qrCode,
                            "road": m?.road,
                            "area": getAreaPath(res2?.data, m?.areaId),
                            "pictures": m?.pictures
                        }
                    })
                    setOriMarkers(oriMarkers)
                    let markers = oriMarkers?.filter((m: any) => (m?.position?.latitude && m?.position?.longitude))
                    setMarkers(markers);
                })

            }
        })
    }

    useEffect(() => {
        handleGetHydrantList();
        handleGetRoads()
    }, [])


    const markerEvents = {
        click: (e:any, marker:any) => {
            console.log('e',e);
            console.log('marker',marker);

                setCenterLat(marker?.w?.extData?.position?.latitude)
                setCenterLng(marker?.w?.extData?.position?.longitude)
                setInfo(marker?.w?.extData);
                setZoom(16);
                setInfoWindowVisible(true);
                setShowImageArea(true);
                // setHydrantDetailVisible(true);


        },
        // ... 支持绑定所有原生的高德 Marker 事件
    };

    const styleC: any = {
        background: `url("https://s.iterated.xyz//image/-MTY5NzE3NzIxMA==-MzI5MDk3NQ==.png")`,
        backgroundSize: 'contain',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        width: '30px',
        height: '30px',
        color: '#000',
        textAlign: 'center',
        lineHeight: '30px',
    }

    const [roads, setRoads] = useState([])

    const handleGetRoads = () => {
        getRoads().then((res: any) => {
            const items = res?.data;
            const roadNames = new Set();
            const uniqueItems = items.filter((item: any) => {
                if (roadNames.has(item.roadName)) {
                    return false;
                } else {
                    roadNames.add(item.roadName);
                    return true;
                }
            });
            setRoads(uniqueItems?.map((d: any) => {
                return {value: d?.roadName, label: d?.roadName}
            }))
        }).catch((err: any) => {
            console.log(err)
        })
    }

    const [roadOptions, setRoadOptions] = useState<any[]>([])
    const getPanelValue = (searchText: string) =>
        !searchText ? [] : roads?.filter((r: any) => {
            return r?.value?.includes(searchText)
        })

    const [showImageArea, setShowImageArea] = useState<boolean>(true);
    function renderMarkerLayout(){
        return <div style={styleC} />
    }

    return (
        <div className={styles.hydrantMap}>
            <div className={styles.header}>
                <div className={styles.return}>
                    <div onClick={() => {
                        navigate("/home")
                    }}>
                        <ArrowLeftOutlined style={{fontSize: '20px'}}/>返回
                    </div>
                </div>

                <div className={styles.nav}>

                    <Cascader onChange={handleDistrictChange} style={{width: 250, marginRight: 30}}
                              options={districtOptions ? [districtOptions] : []}
                              changeOnSelect placeholder="县区/街道/乡镇"/>

                    <Input
                        style={{width: 200, marginRight: 30}}
                        placeholder="名称"
                        allowClear
                        onChange={handleNameChange}
                    />

                    <Input
                        style={{width: 200, marginRight: 30}}
                        placeholder="地址"
                        allowClear
                        onChange={handleAddressChange}
                    />

                    <Input
                        style={{width: 200, marginRight: 30}}
                        placeholder="二维码"
                        allowClear
                        onChange={handleQrcodeChange}
                    />


                    <AutoComplete
                        options={roadOptions}
                        style={{width: 200, marginRight: 30}}
                        onSearch={(text) => setRoadOptions(getPanelValue(text))}
                        placeholder="道路"
                        allowClear
                        onChange={handleRoadChange}
                    />


                    <Button type={'primary'} onClick={handleGetHydrantList}>查询</Button>
                </div>
                <div className={styles.switchBtn}>
                    数据列表
                    <div style={{width: "1vw"}}/>
                    <Switch checkedChildren="显示" unCheckedChildren="隐藏" defaultChecked onChange={onSwitchChange}/>
                </div>
            </div>
            <div className={styles.map}>
                <Map center={{longitude: centerLng, latitude: centerLat}} zoom={zoom}>
                    <Markers
                        events={markerEvents}
                        markers={markers}
                        useCluster
                        render={renderMarkerLayout}
                    />
                    {/*{markers?.map((m: any) => (*/}
                    {/*    <Marker*/}
                    {/*        key={m?.id}*/}
                    {/*        events={markerEvents}*/}
                    {/*        extData={m}*/}
                    {/*        position={{longitude: m?.position?.longitude, latitude: m?.position?.latitude}}>*/}
                    {/*        <div>*/}
                    {/*            /!*<div style={{fontSize:"10px",width:'60px',color:"#757170"}}> {m?.name}</div>*!/*/}
                    {/*            <div style={styleC}/>*/}
                    {/*        </div>*/}

                    {/*    </Marker>*/}
                    {/*))}*/}
                    <InfoWindow
                        position={{
                            longitude: centerLng,
                            latitude: centerLat
                        }}
                        visible={infoWindowVisible}
                        isCustom={false}
                        content={infoWindow}
                        size={{
                            width: 220,
                            height: 200,
                        }}
                        offset={[0, 0]}
                        events={{
                            close: () => {
                                setInfoWindowVisible(false);
                                setShowImageArea(false);
                            }
                        }}
                    />
                    <Button>查看{info?.name}图片</Button>
                    <Modal open={hydrantDetailVisible} onCancel={handleHydrantDetailModalClose}
                           onOk={handleHydrantDetailModalClose}
                           title={`${info?.name}详情`} cancelButtonProps={{style: {display: 'none'}}} okText={'知道了'}>
                        <div>
                            {/*<div className={styles.row}>*/}
                            {/*    <div className={styles.title}>名称</div>*/}
                            {/*    <div className={styles.content}>{info?.name}</div>*/}
                            {/*</div>*/}

                            {/*<div className={styles.row}>*/}
                            {/*    <div className={styles.title}>县区</div>*/}
                            {/*    <div className={styles.content}>{info?.area}</div>*/}
                            {/*</div>*/}

                            {/*<div className={styles.row}>*/}
                            {/*    <div className={styles.title}>道路</div>*/}
                            {/*    <div className={styles.content}>{info?.road}</div>*/}
                            {/*</div>*/}

                            {/*<div className={styles.row}>*/}
                            {/*    <div className={styles.title}>地址</div>*/}
                            {/*    <div className={styles.content}>{info?.address}</div>*/}
                            {/*</div>*/}

                            {/*<div className={styles.row}>*/}
                            {/*    <div className={styles.title}>二维码</div>*/}
                            {/*    <div className={styles.content}>{info?.qrCode||"未绑定"}</div>*/}
                            {/*</div>*/}

                            {
                                info?.pictures?.length > 0 && (
                                    <div className={styles.row}>
                                        <div className={styles.title}>照片</div>
                                        {/*<div style={{cursor:'pointer',color:'#1677ff'}} onClick={()=>{*/}
                                        {/*    setImageVisible(true)*/}
                                        {/*}}>查看</div>*/}
                                        <Image.PreviewGroup
                                            items={info?.pictures?.map((p: any) => (p.url))}
                                        >
                                            <Image
                                                width={200}
                                                src={info?.pictures[0].url}
                                            />
                                        </Image.PreviewGroup>
                                    </div>
                                )
                            }

                        </div>
                    </Modal>


                </Map>

            </div>

            {showImageArea && info?.pictures?.length > 0 && (<div className={styles.imageArea} style={{
                width:`${info?.pictures?.length*125}px`,
                    right: `calc((100vw - ${info?.pictures?.length*125}px) / 2)`}}>
                    <div className={styles.imageAreaHeader}>图片
                    </div>
                    <div className={styles.imageList}>
                        <Image.PreviewGroup
                            items={info?.pictures?.map((p: any) => (p.url))}
                        >
                            {info?.pictures?.map((p: any) => (<Image
                                width={100}
                                height={100}
                                src={p.url}
                            />))}
                            {/*<Image*/}
                            {/*    width={100}*/}
                            {/*    height={100}*/}
                            {/*    src={info?.pictures[0].url}*/}
                            {/*/>*/}
                        </Image.PreviewGroup>
                    </div>
                </div>
            )}


            {showHydrantList && (<div className={styles.assetList}>
                    <div className={styles.listHeader}>XHS列表</div>
                    {oriMarkers?.length === 0 ? (<div
                        className={styles.listItemNoData}>无数据</div>) : oriMarkers?.slice((page - 1) * pageSize, page * pageSize)?.map((mark, index) => (
                        <div className={styles?.list} key={index}
                             onClick={() => {
                                 if (mark?.position?.longitude && mark?.position?.latitude) {
                                     setCenterLng(mark?.position?.longitude);
                                     setCenterLat(mark?.position?.latitude);
                                     setZoom(16);
                                     setInfoWindowVisible(true);
                                     setInfo(mark);
                                     setCheckedId(mark?.id);
                                     setShowImageArea(true)
                                     // setHydrantDetailVisible(true)
                                 } else {
                                     showWarning("该XHS没有定位信息!")
                                 }
                             }}>
                            <div className={mark?.id === checkedId ? styles.listItemChecked : styles.listItem}>
                                <div>{mark?.name}</div>
                                <div style={{
                                    fontSize: '10px',
                                    fontWeight: 'normal',
                                    wordWrap: 'break-word'
                                }}>地址: {mark?.address}</div>
                            </div>

                            <div className={styles.listRight}>
                                <Tooltip title="查看详情">
                                    <ArrowCircleRight style={{cursor: 'pointer'}} onClick={() => {
                                        // checkDetail(mark?.id)；
                                        navigate("/manage/hydrant",{state:{qrCode:mark?.qrCode}})
                                    }} theme="filled" size="24" fill="#2a5cc2" strokeLinecap="square"/>
                                </Tooltip>

                            </div>
                        </div>
                    ))}
                    <div className={styles.listFooter}>
                        <Pagination current={page} total={total} size="small" showSizeChanger={false}
                                    pageSize={pageSize} onChange={handlePageChange}/>
                    </div>

                </div>
            )}


        </div>

    );
};

export default HydrantMap;