import {Button, Cascader, Form, Input, InputNumber, Modal, Select} from 'antd';
import React, {useEffect, useRef, useState} from 'react';
// @ts-ignore
import {debounce} from "lodash"
import { showFail, showNeedCompleteInfo, showNetError, showSuccess, showWarning} from "../functions";
import PickLocationModal from '../pickLocationModal';
import {getAllCompanyNotPage, updateGarbageBox} from "../../api";


const useResetFormOnCloseModal = ({form, open}: { form: any, open: boolean }) => {
    const prevOpenRef: any = useRef();
    useEffect(() => {
        prevOpenRef.current = open;
    }, [open]);
    const prevOpen = prevOpenRef.current;
    useEffect(() => {
        if (!open && prevOpen) {
            form.resetFields();
        }
    }, [form, prevOpen, open]);
};

const EditGarbageBoxModal = ({
                                 open,
                                 onClose,
                                 streetOptions,
                                 handleGetBox,
                                 boxRecord
                             }: { open: boolean, onClose: () => void, streetOptions: any, handleGetBox: any, boxRecord: any }) => {
    const [form] = Form.useForm();
    useResetFormOnCloseModal({
        form,
        open,
    });
    const [record, setRecord] = useState<any>()
    const isSuperManager = JSON.parse(window.localStorage?.getItem("isSuperManager") as string);
    const companyId = window.localStorage?.getItem("companyId");

    const [cleanCompanyList, setCleanCompanyList] = useState<any[]>([])
    useEffect(() => {
        if (open && isSuperManager) {
            getAllCompanyNotPage().then((res: any) => {
                if (res?.code === 200) {
                    console.log('res', res);
                    setCleanCompanyList(res?.data?.map((d: any) => {
                        return {
                            value: d?.companyId,
                            label: d?.companyName
                        }
                    }))
                }
            })
        }
        if (open) {
            form.resetFields();
            setRecord(boxRecord);
            setLoc({lng: boxRecord?.longitude, lat: boxRecord?.latitude});
            setInitLoc({lng: boxRecord?.longitude, lat: boxRecord?.latitude});
        }
    }, [open])

    const [loc, setLoc] = useState<any>()
    const [initLoc, setInitLoc] = useState({lng: 120.09, lat: 30.88});


    //新增宿舍中的选择位置对话框
    const [pickLocModalOpen, setPickLocModalOpen] = useState(false);
    const handleCancelLocModal = () => {
        setPickLocModalOpen(false);
    }


    const confirmLoc = (e: any) => {
        setLoc(e);
        form.setFieldsValue({"location": e})
    }

    const getLocation = () => {
        console.log(navigator.geolocation)
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition((l) => {
                if (l?.coords) {
                    setInitLoc({lng: l?.coords?.longitude, lat: l?.coords?.latitude})
                } else {
                    showFail('获取定位失败')
                }
            });
        } else {
            alert("浏览器不支持地理定位。");
        }
    }

    const [loading, setLoading] = useState<boolean>(false);
    const handleUpdateNewBox = () => {
        form.validateFields().then((value) => {
            let updatedBox = {
                ...value,
                boxCode:record?.boxCode,
                longitude: value?.location?.lng,
                latitude: value?.location?.lat,
                companyId: isSuperManager ? value?.companyId : companyId
            }
            setLoading(true);
            updateGarbageBox(updatedBox).then((res: any) => {
                setLoading(false);
                console.log(res)
                if (res?.code === 200) {
                    handleGetBox().then(() => {
                        showSuccess(res?.msg);
                        onClose();
                        setLoc(undefined);
                    })
                } else {
                    showFail(res?.msg)
                }
            }).catch((err: any) => {
                console.log(err)
            })

        }).catch((err) => {
            console.log(err);
            showNeedCompleteInfo();
        })
    }


    return (
        <div>
            <Modal open={open} onCancel={() => {
                onClose();
                setLoc(undefined);
                setLoading(false)
            }} title={'箱体信息修改'} maskClosable={false} okText={'确认提交'}
                   onOk={debounce(handleUpdateNewBox, 1000)} okButtonProps={{loading: loading}} destroyOnClose>
                <Form form={form} name="form" labelAlign="right" labelCol={{
                    xs: {span: 24},
                    sm: {span: 6},
                }} wrapperCol={{
                    xs: {span: 24},
                    sm: {span: 14},
                }}>
                    {isSuperManager && (
                        <Form.Item
                            name="companyId"
                            label="归属清运公司"
                            initialValue={boxRecord?.companyId}
                            rules={[
                                {
                                    required: true, message: "请选择清运公司"
                                },
                            ]}
                        >
                            <Select style={{width: 250}}
                                    options={cleanCompanyList}
                                    placeholder="清运公司"/>
                        </Form.Item>
                    )}

                    <Form.Item
                        name="streetName"
                        label="街道"
                        initialValue={boxRecord?.streetName}
                        rules={[
                            {
                                required: true, message: "请选择XHS街道"
                            },
                        ]}
                    >
                        <Select style={{width: 250}}
                                options={streetOptions}
                                placeholder="街道/乡镇"/>
                    </Form.Item>

                    <Form.Item
                        name="commName"
                        label="社区名称"
                        initialValue={boxRecord?.commName}
                        rules={[
                            {
                                required: true, message: "请输入社区名称"
                            },
                        ]}
                    >
                        <Input placeholder="请输入社区名称"/>
                    </Form.Item>

                    <Form.Item
                        name="boxName"
                        label="箱体名称"
                        initialValue={boxRecord?.boxName}
                        rules={[
                            {
                                required: true, message: "请输入箱体名称"
                            },
                        ]}
                    >
                        <Input placeholder="请输入箱体名称"/>
                    </Form.Item>


                    <Form.Item
                        name="maxLoadWeight"
                        label="最大载重量"
                        initialValue={boxRecord?.maxLoadWeight}
                        rules={[
                            {
                                required: true, message: "请输入箱体最大载重量"
                            },
                        ]}
                    >
                        <InputNumber addonAfter='kg' min={0} placeholder={'请输入箱体最大载重量'}/>
                    </Form.Item>

                    <Form.Item
                        name="leader"
                        label="箱体负责人姓名"
                        initialValue={boxRecord?.leader}
                        rules={[
                            {
                                required: true, message: "请输入箱体负责人姓名"
                            },
                        ]}
                    >
                        <Input placeholder="请输入箱体负责人姓名"/>
                    </Form.Item>

                    <Form.Item
                        name="telphone"
                        label="负责人联系方式"
                        initialValue={boxRecord?.telphone}
                        rules={[
                            {
                                required: true, message: "请输入负责人联系方式"
                            },
                        ]}
                    >
                        <Input placeholder="请输入负责人联系方式"/>
                    </Form.Item>

                    <Form.Item
                        name="location"
                        label="定位"
                        initialValue={loc}
                        rules={[
                            {
                                required: true, message: "请选择箱体定位"
                            },
                        ]}
                    >
                        {loc ? (<div style={{display: "flex", flexDirection: "row"}}>
                                <div style={{color: "#235c7a"}}>
                                    <div>经度:{loc?.lng}</div>
                                    <div>纬度:{loc?.lat}</div>
                                </div>
                                <div style={{width: "20px"}}/>
                                <div style={{display: "grid", placeItems: "center"}}>
                                    <Button type="primary" onClick={() => {
                                        setPickLocModalOpen(true)
                                    }}>重新选择
                                    </Button>
                                </div>

                            </div>
                        ) : (
                            <Button type="primary" onClick={() => {
                                getLocation();
                                setPickLocModalOpen(true);
                            }}>选择定位
                            </Button>
                        )}
                    </Form.Item>

                </Form>
                <PickLocationModal loc={initLoc} open={pickLocModalOpen} onCancel={handleCancelLocModal}
                                   confirmLoc={(e: any) => {
                                       confirmLoc(e)
                                   }} confirmName={() => {
                }} confirmAddress={() => {}} confirmRoad={() => {
                                   }} repick={undefined}/>
            </Modal>

        </div>
    );
};

export default EditGarbageBoxModal;