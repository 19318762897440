import React from 'react';
import {UploadOutlined} from '@ant-design/icons';
import {Button, Upload, message} from 'antd';

const UpdatePictures = (prop) => {
    const {value, onChange, defaultList, max} = prop;
    let defaultFileList = defaultList?.map((d) => {
        return { url: d?.url}
    })
    const handleChange = ({fileList}) => {
        onChange?.([...fileList]);
    };
    const onRemove = (file) => {
        const files = (value || []).filter((v) => v.url !== file.url);
        if (onChange) {
            onChange(files);
        }
    };


    const getExtraData = (file) => ({
        image: file
    });

    let url = "";
    if (process.env.NODE_ENV === 'development') {
        url = "http://localhost:8014/api";
    } else {
        url = "https://www.nxqyt.com/api";
    }
    const uploadProps = {
        listType: "picture",
        name: 'file',
        fileList: value,
        action:  `${url}/cleanCompany/upload`,
        onChange: handleChange,
        onRemove,
        data: getExtraData,
        headers: {
            Authorization: window.localStorage.getItem('token')
        },
        maxCount: max,
        beforeUpload: (file) => {
            const isPNGorJPG = file?.type === 'image/png' || file.type === "image/jpeg";
            if (!isPNGorJPG) {
                message.error(`仅支持上传JPG或PNG格式的照片`);
            }
            return isPNGorJPG || Upload.LIST_IGNORE;
        },
        defaultFileList

    };
    return (
        <Upload {...uploadProps}>
            <Button icon={<UploadOutlined/>}>{"上传(覆盖原图片)"}</Button>
        </Upload>
    );
};

export default UpdatePictures;